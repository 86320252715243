<template>
  <div
    ref="chainItem"
    class="group/wallet-menu-connected-chain-item relative h-[30px] min-w-[30px] max-w-[30px] md:h-[18px] md:min-w-[18px] md:max-w-[18px]"
    @mouseover="hoverHandler"
  >
    <img
      class="-mr-3 flex h-full w-full cursor-pointer rounded-full border-[1px] border-solid !border-[#33FF99] last:mr-0 hover:outline hover:outline-2 hover:outline-[#33FF99]/50"
      :src="logo"
      :alt="chainName"
    />
    <div
      ref="copyAddressPanel"
      class="absolute top-full z-[1] hidden pt-2 group-hover/wallet-menu-connected-chain-item:flex"
      :class="
        panelPosition === 'left'
          ? 'left-0 translate-x-0'
          : panelPosition === 'right'
            ? 'right-0 translate-x-0'
            : 'left-1/2 -translate-x-1/2'
      "
    >
      <div
        class="flex cursor-pointer flex-col rounded-[10px] border border-solid border-[#33FF99]/20 bg-[#23362b] px-3.5 py-2"
        :class="
          savedClipboard ? 'border-[#ECBA33]/20 bg-[#353022]' : 'border-[#33FF99]/20 bg-[#23362b]'
        "
        @click="handleCopyAsset"
      >
        <div
          class="larken flex items-center gap-1 text-xs"
          :class="savedClipboard ? 'text-[#ecba33]' : 'text-[#33FF99]'"
        >
          {{ savedClipboard ? 'Address Copied!' : chainName }}
        </div>
        <button class="flex items-center gap-1 text-[10px] leading-none text-white/70">
          <div
            class="visible col-start-1 row-start-1 aspect-square w-1.5 rounded-full border border-solid border-[#33FF9959] bg-[#33FF99]"
          />
          <span class="visible col-start-2 row-start-1 opacity-50">{{ shortString(address) }}</span>
          <Icon icon="lucide:copy" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { ref } from 'vue'
import { shortString } from '~/utils/helpers'
import { copyToClipboard } from '~/utils/utils'

const props = defineProps<{
  address: string
  logo: string
  chainName: string
}>()

const savedClipboard = ref(false)
const handleCopyAsset = () => {
  copyToClipboard(props.address)
  savedClipboard.value = true
  setTimeout(() => {
    savedClipboard.value = false
  }, 3000)
}

const chainItem = ref<HTMLElement | null>()
const copyAddressPanel = ref<HTMLElement | null>()
const panelPosition = ref<'left' | 'center' | 'right'>('left')

const hoverHandler = () => {
  if (!chainItem.value || !copyAddressPanel.value || !chainItem.value.offsetParent) return
  const itemWidth = chainItem.value.offsetWidth
  const offsetLeft = chainItem.value.offsetLeft
  const parentWidth = chainItem.value.offsetParent.clientWidth
  const copyAddressPanelWidth = copyAddressPanel.value.clientWidth
  if (offsetLeft + itemWidth / 2 < copyAddressPanelWidth / 2) {
    panelPosition.value = 'left'
  } else if (parentWidth - offsetLeft - itemWidth / 2 < copyAddressPanelWidth / 2) {
    panelPosition.value = 'right'
  } else {
    panelPosition.value = 'center'
  }
}
</script>
